import React from "react"
// import { graphql, useStaticQuery } from "gatsby"
// import Img from "gatsby-image"

import Background from "../images/events-img/1-python-folder/background1.jpg"
import MyLogo from "../images/logo.png"
import './main-banner.css'

const divStyle = {
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    opacity: '0.7',
    backgroundImage: 'url(' + Background + ')',
  };

const MainBanner = () => {
    // const data = useStaticQuery(graphql`
    // query {
    //     background1: file(relativePath: {eq: "background1.jpg"}){
    //         childImageSharp{
    //             fluid(maxWidth: 3000){
    //                 ...GatsbyImageSharpFluid
    //             }
    //         }
    //     }
    // }`)

    return(
    <div>
        <div className="main-banner-bg" id="home" style={divStyle}>
            <div className="main-banner-content">
                {/* <Img fluid={data.background1.childImageSharp.fluid} /> */}
                <img src={MyLogo} alt="logo" className="main-banner-logo"> 
                </img>
            </div>
        </div>
    </div>
    )
}

export default MainBanner;