import React from "react"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import "./value.css"

const Value = (props) =>(
    <div className="value-box">
        <div className="value-circle">
            <FontAwesomeIcon className="value-icon" icon={props.icon}/>
        </div>
        <div className="value-name">
            {props.name}
        </div>
    </div>
)

export default Value;