import React from "react"
import "./about-us.css"
import Value from "./value"
import { faHandHoldingHeart } from '@fortawesome/free-solid-svg-icons'
import { faBalanceScale } from '@fortawesome/free-solid-svg-icons'
import { faFistRaised } from '@fortawesome/free-solid-svg-icons'
import emma_body from "../images/robo.png"
import emma_head from "../images/gatsby-icon.png"

const AboutUs = () =>(
    <div className="about-container" id="about">
        <div className="mobile-title">
            <p className="section-title">SOBRE NÓS</p>
            <img className="emma-head" src={emma_head} alt=""/>
        </div>
        <div className="about-flex">
            <p className="about-description">
                O Elas pelas Exatas nasceu da insatisfação com a falta de representatividade de mulheres nas áreas de STEM (ciencia, tecnologia, engenharia e matemática) e da vontade de mudar essa realidade. Nosso objetivo é incentivar e promover o ingresso e a permanência de jovens mulheres nos ramos de exatas e tecnologia para formar uma nova geração de líderes nessas áreas. Para cumprir esse objetivo, atuamos em duas frentes. A primeira é voltada para meninas dos ensinos fundamental e médio, focada principalmente na realização de cursos e workshops técnicos e rodas de conversa sobre universidades. A segunda é voltada para jovens universitárias, com eventos de soft skills e rodas de conversa sobre mercado de trabalho e os desafios de ser mulher no ramo.
                Para saber mais sobre o projeto, entre em contato com a gente!
            </p>
            <img src={emma_body} className="emma-img" alt="emma-body"/>
        </div>
        <div className="values-box">
            <Value
            icon = {faHandHoldingHeart}
            name="sororidade"
            />
            <Value
            icon = {faFistRaised}
            name="empoderamento"
            />
            <Value
            icon = {faBalanceScale}
            name="igualdade"
            />
        </div>
    </div>
)

export default AboutUs;