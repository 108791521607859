import React, { Component } from "react"
// import { graphql, useStaticQuery } from "gatsby"

import "./events.css"
import EventCard from "./event-card"
import Python from "../images/events-img/card-img/1-curso-python-min.jpg"
import Segep from "../images/events-img/card-img/elas-segep-min.jpg"
import Senec from "../images/events-img/card-img/elas-senec-min.jpg"
import Invista from "../images/events-img/card-img/invista-min.jpg"
import InvistaOnline from "../images/events-img/card-img/invista-online.png"
import Lives2020 from "../images/events-img/lives-2020.png"
import Simposio from "../images/events-img/i-smstem.jpeg"
import Saude from "../images/events-img/card-img/ws-saude-landscape-min.jpg"
import Investimentos from "../images/events-img/investimentos.png"

// export const myQuery = graphql`
//     query {
//         imgimg: file(relativePath: {eq: "events-img/elas-senec.jpg"}){
//             childImageSharp{
//                 fixed(width: 180){
//                   ...GatsbyImageSharpFixed
//                 }
//             }
//         }
//     }`

class Events extends Component {
    constructor() {
        super()
        this.state = {
            hidden: true
        }
    }
    show(){
        this.setState({
            hidden: !this.state.hidden
        })
    }
    
  render(){
        return(
            <div className="events-container" id="events">
                <p className="section-title events-title">PROJETOS</p>

                <div className="first-cards-row">
                    {/* <EventCard
                        photo = { Pesquisa }
                        name = "Pesquisa: Áreas de Interesse de Universitários"
                        date = "19/07/2019"
                        description = "Descrição."
                        link = "/pesquisa-interesses"
                    /> */}
                    <EventCard
                        photo = { Python }
                        name = "1° Curso de Python"
                        date = "Julho/2019"
                        description = "O Curso de Programação em Python, em parceria com a Wildlife Studios, visa promover o contato das alunas com a lógica de programação e programação na linguagem Python."
                        link = "/1-curso-python"
                    />
                    <EventCard
                        photo = { Senec }
                        name = "Elas na SenEC"
                        date = "Setembro/2019"
                        description = "O Workshop de Programação em Blocos visa promover o contato das alunas com a lógica e a programação em blocos, através da interface de programação Scratch."
                        link = "/elas-na-senec"
                    />
                    <EventCard
                        photo = { Segep }
                        name = "Elas na SEGEP"
                        date = "Setembro/2019"
                        description = "O Workshop de Programação em Blocos visa promover o contato das alunas com a lógica e a programação em blocos, através da interface de programação Scratch."
                        link = "/elas-na-segep"
                    />
                    </div>
                    {
                        this.state.hidden?
                            null
                            :
                            <div className="more-cards-rows">
                            <EventCard
                                photo = { Saude }
                                name = "Workshop de Saúde Emocional para Mulheres"
                                date = "Agosto/2019"
                                description = "Workshop de saúde emocional com foco em mulheres em STEM, realizado em parceria com a ONG Associação pela Saúde Emocional de Crianças (ASEC)."
                                link = "/ws-saude-emocional"
                            />
                            <EventCard
                                photo = { Invista }
                                name = "Roda de Conversa sobre Investimentos"
                                date = "Outubro/2019"
                                description = "Roda de conversa sobre investimentos e educação financeira, em parceria com o Invista como uma garota."
                                link = "/roda-investimentos"
                            />
                            <EventCard
                                photo = { Simposio }
                                name = "I Simpósio de Mulheres em STEM (SMSTEM)"
                                date = "Março/2020"
                                description = "Apresentação de pôster no I Simpósio de Mulheres em STEM realizado no Instituto Tecnológico de Aeronáutica (ITA)."
                                link = "/i-smstem"
                            />
                            <EventCard
                                photo = { InvistaOnline }
                                name = "Roda de Conversa sobre Investimentos"
                                date = "Maio/2020"
                                description = "Roda de conversa online sobre investimentos e educação financeira, em parceria com o Invista como uma garota."
                                link = "/roda-investimentos-online"
                            />
                            <EventCard
                                photo = { Lives2020 }
                                name = "Lives e Mesas Redondas 2020"
                                date = "Junho-Outubro/2020"
                                description = "Lives e Mesas Redondas com temas diversos: saúde, educação, carreira, mercado de trabalho e diversidade."
                                link = "/lives-2020"
                            />
                            <EventCard
                                photo = { Investimentos }
                                name = "Curso de Investimentos"
                                date = "Fevereiro/2021"
                                description = "Curso online de investimentos para mulheres, compreendendo os primeiros passos para investir."
                                link = "/investimentos"
                            />
                            </div>
                    }
                <div className="show-more-button" onClick={()=> this.show()}>{this.state.hidden? "ver mais projetos" : "ver menos projetos"}</div>
            </div>
        )
    }
}

export default Events;