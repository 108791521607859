import React from "react"
import { Link } from "gatsby"
import "./members.css"


const Members = () =>(
    <div className="members-container" id="members">
        <p className="members-title">NOSSAS INTEGRANTES</p>
        <p className="members-description">
        O Elas pelas Exatas não seria nada sem a dedicação das nossas integrantes. Todas atuam de forma voluntária por acreditarem no potencial transformador do projeto. Graças a elas conseguimos crescer e mudar a vida de meninas e mulheres.
        </p>
        <Link to="/members/" className="our-time-button">conheça toda nossa equipe</Link>
    </div>
)

export default Members
