import React from"react"
// import Img from "gatsby-image"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCalendar } from '@fortawesome/free-solid-svg-icons'
import "./event-card.css"

const EventCard = (props) =>(
    <div className="event-card">
        <div className="event-photo-div">
            <img className="event-photo" src={props.photo} alt="" />
        </div>
        <div className="card-body">
            <div className="event-name">{props.name}</div>
            <div className="event-date">
                <FontAwesomeIcon className="event-icon" icon={faCalendar} />
                <div className="date-number">{props.date}</div>
            </div>
            <div className="event-desciption">{props.description}</div>
            <a href={props.link} className="event-link"><div className="event-button">saiba mais</div></a>
        </div>
    </div>
)

export default EventCard;