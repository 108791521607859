import React from "react"
import { Helmet } from "react-helmet"

import 'bootstrap/dist/css/bootstrap.min.css';
import EmmaHead from "../images/gatsby-icon.png"
import MyFooter from "../components/footer.js"
import MyNavbar from "../components/navbar.js"
import MainBanner from "../components/main-banner.js"
import AboutUs from "../components/about-us.js"
import Events from "../components/events.js"
import Members from "../components/members.js"

import "./index.css"

const IndexPage = () => {
  
    return(
    <div className="index">
      <Helmet>
        <title>Elas pelas Exatas</title>
        <link rel="icon" href={EmmaHead} />
      </Helmet>
      
        <MyNavbar />
        <MainBanner />
        <AboutUs />
        <Events />
        <Members />
        <MyFooter />
    </div>
  )
}

export default IndexPage
